<template>
  <div>
    <div class="text-center pt-4" v-if="loading">
      <b-spinner label="Cargando..."/>
      Cargando...
    </div>

    <b-alert
        variant="warning"
        v-if="errorMessage"
        show
    >
      <div class="alert-body font-small-2">
        <p>
          <small class="mr-50">
            <span class="font-weight-bold">{{ errorMessage }}</span>
          </small>
        </p>
      </div>
    </b-alert>

    <b-form @submit.prevent="save()">
      <b-row>
        <b-col cols="6">
          <label>
            Fecha
          </label>
          <b-form-group>
            <b-form-datepicker
                id="dateE-datepicker"
                v-model="selectedSince"
                class="mb-1"
                placeholder="Fecha"
                :rules="[rules.required]"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="es"
                @input="formatDateSince(selectedSince + 'T23:59:59.999Z')"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Semana Epidemiológico" label-for="weekE">
            <b-form-input id="weekE" type="text" v-model="item.week"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Región"
              label-for="region"
          >
            <b-form-select
                v-model="region"
                :options="locations.regiones"
                options-field="none"
                id="region"
                text-field="region"
                @change="getDistritos()"
            >
              <template #first>
                <b-form-select-option :value="null">Seleccione una opción</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Distrito"
              label-for="district"
          >
            <b-form-select
                v-model="district"
                :options="locations.distritos"
                options-field="none"
                id="distrito"
                text-field="distrito"
                @change="getCorregimientos()"
            >
              <template #first>
                <b-form-select-option :value="null">Seleccione una opción</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
              label="Corregimiento"
              label-for="corregimiento"
          >
            <b-form-select
                v-model="corregimiento"
                :options="locations.corregimientos"
                options-field="none"
                id="corregimiento"
                text-field="corregimiento"
                @change="getLocalidades()"
            >
              <template #first>
                <b-form-select-option :value="null">Seleccione una opción</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
              label="Localidad"
              label-for="localidad"
          >
            <b-form-select
                v-model="localidad"
                :options="locations.localidades"
                options-field="none"
                id="localidad"
                text-field="localidad"
            >
              <template #first>
                <b-form-select-option :value="null">Seleccione una opción</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <!-- Semana columns -->
        <b-col cols="1" v-for="(field, index) in fields.slice(0, 1)" :key="field.field">
          <b-form-group :label="field.label" :label-for="field.label + '-' + index">
            <b-form-input :id="field.label + '-' + index" :type="field.type" v-model="item[field.field]" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- First set of columns -->
        <b-col cols="1" v-for="field in fields.slice(1, 5)" :key="field.field"  :cols="field.cols">
          <b-form-group :label="field.label" :label-for="field.field">
            <b-form-input :id="field.field" :type="field.type" v-model="item[field.field]" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <h6>Viviendas Infectadas</h6>
      <b-row>
        <b-col cols="4"></b-col>
        <b-col offset="2">Colonización</b-col>
      </b-row>
      <b-row>
        <!-- Second set of columns -->
        <b-col cols="1" v-for="field in fields.slice(5, 13)" :key="field.field" :offset="field.offset">
          <b-form-group :label="field.label"  :label-for="field.field">
            <b-form-input :id="field.field" :type="field.type" v-model="item[field.field]" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <h6>Cantidad de Chinche</h6>
      <b-row>
        <b-col cols="4">Intradomiciliar</b-col>
        <b-col offset="2">Peridomiciliar</b-col>
      </b-row>
      <b-row>
        <!-- Third set of columns -->
        <b-col cols="1" v-for="field in fields.slice(13, 21)" :key="field.field" :offset="field.offset">
          <b-form-group :label="field.label" :label-for="field.field">
            <b-form-input :id="field.field" :type="field.type" v-model="item[field.field]" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <h6>Tipo de Pared</h6>
      <b-row>
        <!-- Fourth set of columns -->
        <b-col cols="1" v-for="field in fields.slice(21, 31)" :key="field.field">
          <b-form-group :label="field.label" :label-for="field.field">
            <b-form-input :id="field.field" :type="field.type" v-model="item[field.field]" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <h6>Tipo de Techo</h6>
      <b-row>
        <!-- Fifth set of columns -->
        <b-col cols="1" v-for="field in fields.slice(31, 35)" :key="field.field">
          <b-form-group :label="field.label" :label-for="field.field">
            <b-form-input :id="field.field" :type="field.type" v-model="item[field.field]" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <h6>Tipo de Piso</h6>
      <b-row>
        <!-- Sixth set of columns -->
        <b-col cols="1" v-for="field in fields.slice(35, 39)" :key="field.field">
          <b-form-group :label="field.label" :label-for="field.field">
            <b-form-input :id="field.field" :type="field.type" v-model="item[field.field]" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <hr>
      <h6>Habitantes</h6>
      <b-row>
        <!-- Seventh set of columns -->
        <b-col cols="1" v-for="field in fields.slice(39, 42)" :key="field.field" :offset="field.offset">
          <b-form-group :label="field.label" :label-for="field.field">
            <b-form-input :id="field.field" :type="field.type" v-model="item[field.field]" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" class="mt-2">
          <b-alert
              variant="warning"
              v-if="errorMessage"
              show
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50">
                  <span class="font-weight-bold">{{ errorMessage }}</span>
                </small>
              </p>
            </div>
          </b-alert>
        </b-col>
        
        <b-col class="mt-2 mb-4">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
          >
            <span v-if="!loading">Guardar</span>
            <span v-if="loading">Un momento...</span>
          </b-button>
          <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              @click="$router.go(-1)"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BAlert, BAvatar, BBadge, BPagination, BForm, BFormGroup, BFormInput, BFormSelect, BRow, BCol,
  BButton, BDropdown, BDropdownItem, BFormDatepicker, BToast, BSpinner, BFormFile, BFormSelectOption
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import BCardCode from '@core/components/b-card-code'
import Ripple from 'vue-ripple-directive'
import environment from "@/environment";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {format} from "date-fns";

export default {
  name: 'ChagasAdd',
  components: {
    BAlert,
    BAvatar,
    BBadge,
    BCardCode,
    BPagination,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormDatepicker,
    BToast,
    BSpinner,
    BFormFile,
    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isValid: false,
      selectedSince: null,
      selectedSince: '',
      region: '',
      district: '',
      corregimiento: '',
      localidad: '',
      fields: [
        { field: 'semana', label: 'Semana N°', type: 'number' },
        { field: 'numero', label: 'N°', type: 'number' },
        { field: 'numCasasExis', label: 'N° Casas Exist.', type: 'number' },
        { field: 'numCasasInsp', label: 'N° Casas Insp.', type: 'number' },
        { field: 'numCasasRoci', label: 'N° Casas Rocia-Das', type: 'number' },
        { field: 'numCasasInfec', label: 'N° Casas Infectada', type: 'number' },
        { field: 'numCasasRhod', label: 'Con Rhodnius ..p.', type: 'number' },
        { field: 'numCasasTria', label: 'Con Triatoma.d.', type: 'number' },
        { field: 'porcentajeInf', label: 'Porcentaje Inf.', type: 'number' },
        { field: 'coloRPInt', label: 'RP-Int.', type: 'number', offset: 2 },
        { field: 'coloRPPer', label: 'RP-Per. ', type: 'number' },
        { field: 'coloTDInt', label: 'TD-Int.', type: 'number', offset: 1 },
        { field: 'coloTDPer', label: 'TD-Per. ', type: 'number' },
        { field: 'intraRPA', label: 'RP-A', type: 'number' },
        { field: 'intraRPN', label: 'RP-N', type: 'number' },
        { field: 'intraTDA', label: 'TD-A', type: 'number' },
        { field: 'intraTDN', label: 'TD-N', type: 'number'},
        { field: 'periRPA', label: 'RP-A', type: 'number',offset: 2  },
        { field: 'periRPN', label: 'RP-N', type: 'number' },
        { field: 'periTDA', label: 'TD-A', type: 'number' },
        { field: 'periTDN', label: 'TD-N', type: 'number' },
        { field: 'carton', label: 'Carton', type: 'number' },
        { field: 'paredPaja', label: 'Paja', type: 'number' },
        { field: 'paredPenca', label: 'Penca', type: 'number' },
        { field: 'paredVara', label: 'Vara', type: 'number' },
        { field: 'paredTabla', label: 'Tabla', type: 'number' },
        { field: 'paredBarro', label: 'Barro', type: 'number' },
        { field: 'paredZinc', label: 'Zinc', type: 'number' },
        { field: 'paredBloque', label: 'Bloque-Cemento', type: 'number' },
        { field: 'paredLadrillo', label: 'Ladrillo', type: 'number' },
        { field: 'paredRepello', label: 'Repello', type: 'number' },
        { field: 'techoPaja', label: 'Paja', type: 'number' },
        { field: 'techoPenca', label: 'Penca', type: 'number' },
        { field: 'techoTeja', label: 'Teja', type: 'number' },
        { field: 'techoZinc', label: 'Zinc', type: 'number' },
        { field: 'pisoTierra', label: 'Tierra', type: 'number' },
        { field: 'pisoTabla', label: 'Tabla', type: 'number' },
        { field: 'pisoCemento', label: 'Cemento', type: 'number' },
        { field: 'pisoOtros', label: 'Otros', type: 'number' },
        { field: 'mayor', label: '<5', type: 'number' },
        { field: 'menorIgual', label: '<5', type: 'number' },
        // { field: 'dh', label: 'D/H', type: 'number', offset: 1 }
      ],
      item: {dh: 0},
      errorMessage: '',
      loading:false,
      locations: {
        regiones: [],
        corregimientos: [],
        distritos: [],
        localidades: []
      },
      rules: {
        required: (value) => !!value || "Este campo es requerido",
      },
    }
  },
  mounted() {
    this.getRegiones()
  },
  methods: {
    async getRegiones() {
      try {
        this.loading = true
        const resp = await this.$http.get(`${environment.uri}stats/regiones`)
        this.locations.regiones = resp.data.map(r => {
          return {...r, value: r}
        })
      } catch (error) {
        console.error(error)
        this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
      } finally {
        this.loading = false
      }
    },
    async getDistritos() {
      try {
        this.loading = true
        const resp = await this.$http.get(`${environment.uri}stats/distritos?id_region=${this.region.id_region}`)
        this.locations.distritos = resp.data.map(r => {
          return {...r, value: r}
        })
      } catch (error) {
        console.error(error)
        this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
      } finally {
        this.loading = false
      }
    },
    async getCorregimientos() {
      try {
        this.loading = true
        const resp = await this.$http.get(`${environment.uri}stats/corregimientos?id_region=${this.region.id_region}&codigo_distrito=${this.district.codigo_distrito}`)
        this.locations.corregimientos = resp.data.map(r => {
          return {...r, value: r}
        })
      } catch (error) {
        console.error(error)
        this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
      } finally {
        this.loading = false
      }
    },
    async getLocalidades() {
      try {
        this.loading = true        
        const resp = await this.$http.get(`${environment.uri}stats/localidades?id_region=${this.region.id_region}&codigo_distrito=${this.district.codigo_distrito}&codigo_corregimiento=${this.corregimiento.codigo_corregimiento}`)
        this.locations.localidades = resp.data.map(r => {
          return {...r, value: r}
        })
      } catch (error) {
        console.error(error)
        this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
      } finally {
        this.loading = false
      }
    },
    async save() {
      try {
        this.loading = true
        this.errorMessage = ''

        this.item.dh = 0
        this.item.regionLabel = this.region.region
        this.item.districtLabel = this.district.distrito
        this.item.corregimientoLabel = this.corregimiento.corregimiento
        this.item.localidadLabel = this.localidad.localidad

        this.item.region = this.region.id_region
        this.item.district = this.district.id_distrito
        this.item.corregimiento = this.corregimiento.id_corregimiento
        this.item.localidad = this.localidad.id_localidad

        await this.$http.post(`${environment.uri}stats/chagas`, this.item)

        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Guardado`,
            icon: 'CoffeeIcon',
            variant: 'Info',
            text: `Has registrado exitosamente`,
          },
        })
        this.item = {}
        await this.$router.push('/stats/chagas/list')
      } catch (error) {
        console.error(error)
        this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
      } finally {
        this.loading = false
      }
    },
    async formatDateSince(a) {
      try {
        this.loading = true
        const date = new Date(a);
        const fdate = format(date, 'yyyy-MM-dd')
        this.item.dateE = fdate
        const resp = await this.$http
        .get(`${environment.uri}weeks/${fdate}`)
        this.item.week = resp.data.week
      } catch(error) {
        console.error(error)
        this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style scoped>
/* Add any custom styles if needed */

</style>
